.evCatBox {
  display: grid;
  justify-items: flex-start;
  width: 80%; 
}
.tabName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
.evCatDesc {
	overflow: hidden;
	white-space: normal;
	text-overflow: ellipsis;
	max-width: 100%;
	color: gray;
	font-weight: normal;
	font-size: 12px;
	text-align: left;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}
.main {
	width: 340px;
	display: flex;
}
.evCatImg {
	width: 60px;
	height: 60px;
	margin-right: 10px;
	border-radius: 6px;
	object-fit: cover;
}