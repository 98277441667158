.eventContainer {
  padding: 10px 0 20px 0;
}
.evMain {
  padding-bottom: 30px
}
.evCatName {
  font-size: 18px;
  font-weight: bold;
}
.evCatNameCol {
  display: flex;
  flex-direction: column;
}
.evCatDesc {
  color: rgba(0, 0, 0, 0.45);
 
}
.evCatRow {
  padding-bottom: 30px;
}
.evDate {
  font-size: 12px;
  text-align: center;
}
.evDay{
  font-weight: bold;
  text-align: center;
  font-size: 18px;
}
.evTime {
  font-size: 12px;
  color: white;
  padding: 5px;
}
.evTimeBox{
  width: 75px;
  margin-right: 20px; 
}
.evGrayTimeBox {
  background-color: #E8E8E8;
  width: 100%;
  display: grid;
  padding: 5px;
}
.evBlackTimeBox {
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
}
.evImg {

  border-radius: 6px;
  margin-left: 15px;
  object-fit: cover;
}
.evPrice {
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  width: 100%;
  text-align: end;
}
.priceRow {
  display: flex;
  justify-content: space-between;
}
.evDesc {
  color: rgba(0, 0, 0, 0.45);
  overflow: hidden;
	white-space: normal;
	text-overflow: ellipsis;
  max-width: 100%;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.evName {
  font-weight: bold;
  font-size: 16px;
  overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
  max-width: 100%;
}
.evAddress {
  padding-left: 5px;
  overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
  max-width: 100%;
  padding-top: 10px;
}
.evDetailsCol {
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  width: 10%;
}
.evRedText {
  font-size: 12px;
  color: #ed1620;
  white-space: nowrap;
  width: 100%;
  text-align: end;
}
.evPriceCol {
  display: flex;
  justify-content: flex-end;
}
.catImgDiv {
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
}

@media screen and (max-device-width: 667px) {
  .eventContainer {
    padding-bottom: 0;
  }
  .evMain {
    padding-bottom: 10px
  }
  .evCatImgCol {
    display: flex;
    justify-content: center;
  }
  .evCatName {
    padding-left: 0;
    font-size: 16px;
  }
  .evName, 
  .evPrice {
    font-size: 14px;
  }
  .evDesc {
    font-size: 12px;
  }
  .evDay{
    font-size: 16px;
  }
  .evTime,
  .evDate,
  .evRedText {
    font-size: 10px;
  }
  .priceRow {
    padding-top: 15px
  }
  .evTimeBox{
    width: 65px;
    margin-right: 15px; 
  }
  .evCatRow {
    flex-direction: column;
  }
  .evAddress {
    font-size: 12px;
    padding-top: 0;
  }
}
