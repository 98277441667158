.name {
  font-weight: bold;
  font-size: 18px;
}
.price {
  font-weight: bold;
  font-size: 16px;
}
.textDesc {
  color: gray;
  margin-bottom: 20px;
}
.titleRow {
  margin-top: 20px;
}
.retailImg {
  height: 200px;
  width: 200px;
  border-radius: 6px;
}
.titleColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px 20px 0 ;
}
.quantityCol {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 25px;
  width: 100%;
}
.quantityButton {
  width: 48px;
  height: 48px;
  background-color: white;
  align-self: center;
  justify-content: center;
  align-items: center;
  border-width: 1;
  border-radius: 100%;
  padding: 0;
}
.quantityButtonMinus {
  width: 48px;
  height: 48px;
  background-color: white;
  align-self: center;
  justify-content: center;
  align-items: center;
  border-width: 1;
  border-radius: 100%;
  padding: 0;
}
.quantityIcon {
  color: black;
  font-size: 24px;
  margin-top: 3px;
}
.quantityText {
  font-size: 18px;
}
.addVariantButton {
  width: 70%;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin: 20px 10px 40px 10px;
  height: 50px;
  margin-bottom: 30px;
}
.addSimpleButton {
  width: 70%;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin: 20px 10px 40px 10px;
  height: 50px;
  margin-bottom: 30px;
}
.variantButton {
  border-radius: 6px;
  margin-top: 10px;
  margin-right: 10px;
  color: white;
  padding: 10px;
  cursor: pointer;
  min-width: 48px;
  text-align: center;
  background-color: black;
}
.grayBtn {
  background-color: white;
  border-radius: 6px;
  border: 1px solid gray;
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
  min-width: 48px;
  text-align: center;
}
.textBtn {
  padding: 10px;
  border-radius: 6px;
  margin-right: 10px;
  margin-top: 10px;
  color: gray;
  cursor: pointer;
}
.variants {
  padding-bottom: 30px;
}
.textInStock {
  padding: 10px 0 16px 0;
  font-weight: bold;
}
.variantName {
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-device-width: 667px) {
  .titleRow {
    flex-direction: column;
  }
  .retailImg {
    height: 150px;
    width: 150px;
  }
  .name {
    padding-bottom: 10px;
  }
  .titleColumn {
    padding: 0;
  }
  .addSimpleButton,
  .addVariantButton {
    width: 100%;
    font-size: 16px;
    margin: 10px 0 10px 0;
    height: 40px;
  }

  .imgCol {
    display: flex;
    justify-content: center;
  }
  .variantButton,
  .grayBtn,
  .textBtn,
  .textInStock {
    font-size: 12px;
  }
  .variantButton,
  .grayBtn,
  .textBtn {
    padding: 6px;
  }
  .variantName {
    font-size: 18px;
  }
  .variantButton,
  .grayBtn {
    min-width: 40px;
  }
}
@media screen and (max-device-width: 1200px) {
  .titleRow {
    flex-direction: column;
  }
}
