.stadiumFeaturedImageOpen {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  object-fit: cover; 
}

.stadiumFeaturedImageClosed {
  filter: brightness(35%);
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  object-fit: cover; 
}

.closedStadiumText {
  font-weight: bolder;
}

.textContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -250%);
}

.promoDiv {
  background-color: #EF3340;
  position: absolute;
  right: -1px;
  padding: 5px;
  bottom: 85px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  height: 30px;
}

.promoText {
  color: white;
  font-weight: 600;
}

.alignRowCenter {
  align-items: center;
}

.businessLogo {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 6px;
  max-height: 50px;
  max-width: 50px;
}

.itemImg {
  background-size:cover;
  height: 50px;
  width: 50px;
  border-radius: 6px;
  max-height: 50px;
  max-width: 50px;
}

.businessCard {
  width: '100%';
  border-radius: 6px;
}

.floatRight {
  float: right;
}

.feeText {
  color: rgba(0, 0, 0, 0.45);
  white-space: initial;
}

.paddingRightText {
  padding-right: 5px;
}

.singleIconContainer{
  display: flex;
  background: rgba(0,0,0,.5);
  border-radius: 6px;
  padding: 4px;
}

.compoundIconContainer{
  display: flex;
  background: rgba(0,0,0,.5);
  border-radius: 6px;
  margin-right: 2px;
  padding: 4px;  
  text-align: center;
}

.iconsContainer{
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
}
.icon{
  margin: 5px;
  color: white;
}

.iconText{
  color: white;
  font-weight: bold;
  font-size: 12px;
  padding: 4px;
}