.container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mobileHeaderTitleText {
  font-weight: 600;
  font-size: 16px;
}
.desktopHeaderTitleText {
  font-weight: 600;
  font-size: 22px;
}
.iconContainer {
  margin-top: 4px;
}
.socialIcon {
  margin-right: 2px;
}
.imageStyle {
  margin-bottom: 28px;
  border-radius: 6px;
}
