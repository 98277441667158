.background-form {
  background-color: white;
  max-width: 500px;
  width: 100%;
  padding: 2rem;
  border-radius: 6px;
}

.login-form-button {
  margin-right: 10px;
}

.input:hover {
  border-color: var(--border-color) !important;
}

.loginBtn:hover {
  border-color: var(--border-color) !important;
  color: black !important;
}


.forgotBtn {
  border-width: 0;
  box-shadow: none !important;
  color: #f54242;
}

.forgotBtn:hover {
  color: black !important;
  cursor: pointer;
}