.evCatName {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
}
.evImg {
  border-radius: 6px;
  height: 200px;
  width: 200px;
}
.titleColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding:  0 20px 0 0;
}
.textDesc {
  color: gray;
  margin-bottom: 10px;
}
.name {
  font-weight: bold;
  font-size: 18px;
}
.descDiv {
  display: flex;
  flex-direction: column;
}
.quantityCol {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 25px;
  width: 100%;
}
.quantityButton {
  width: 48px;
  height: 48px;
  background-color: white;
  align-self: center;
  justify-content: center;
  align-items: center;
  border-color: #ed1620;
  border-width: 1;
  border-radius: 100%;
  padding: 0;
}
.quantityButtonMinus {
  width: 48px;
  height: 48px;
  background-color: white;
  align-self: center;
  justify-content: center;
  align-items: center;
  border-width: 1;
  border-radius: 100%;
  padding: 0;
}
.quantityIcon {
  color: black;
  font-size: 24px;
  margin-top: 3px;
}
.quantityText {
  font-size: 18px;
}

.addButton {
  width: 70%;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin: 20px 10px 40px 10px;
  height: 50px;
}
.generalInfo,
.location {
  font-size: 16px;
  font-weight: bold;
}
.admName {
  font-size: 18px;
  font-weight: 500;
}
.admDesc {
  font-size: 12px;
  color: gray;
}
.admPrice {
  font-size: 22px;
  padding-top: 10px;
  font-weight: bold;
}
.admRedText {
  font-size: 12px;
  color: red;
}
.admMaxText{
  font-size: 12px;
  color: gray;
}
.evImgCol {
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
}
.imgCol {
  display: flex;
  margin-right: 25px;
}
.titleRow {
  justify-content: flex-start;
  padding-bottom: 15px; 
}

@media screen and (max-device-width: 667px) {
  .evTitleRow {
    flex-direction: column;
  }
  .imgCol {
    width: 100%;
    justify-content: center;
    padding-bottom: 20px;
  }
  .titleColumn {
    padding-left: 0;
    padding-right: 0;
  }
  .addButton {
    width: 100%;
    font-size: 16px;
    margin: 10px 0 10px 0;
    height: 40px;
  }
  .admPrice {
    font-size: 20px;
  }
  .textDesc {
    margin-bottom: 0;
  }
}