@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Montserrat-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/Montserrat-Bold.woff') format('woff');
}

html,
body {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--background-color);
  border-color: var(--border-color);
}

.ant-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color) !important; 
  font-weight: 500;
}

.ant-tabs-tab:hover {
  color: var(--color) !important;
  background-color: var(--backgroundColor) !important;
}

/* .ant-tabs-ink-bar {
  background: none;
} */


/* .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color);
} */

.headerDesign {
  background-color: white !important;
}

.divider {
  background-color: white !important;
  float: right;
}

.ant-list-item:hover {
  box-shadow: 0 0 4px #eee;
}

.homeCarousel1 {
  background-image: url('https://extra-getit.s3.amazonaws.com/webOrdering/woodTable.jpeg');
  height: 425px;
  background-size: cover;
}

.homeCarousel1mobile {
  background-image: url('https://extra-getit.s3.amazonaws.com/webOrdering/woodTable.jpeg');
  height: 300px;
  background-size: cover;
}

.homeCarousel3 {
  background-image: url('https://extra-getit.s3.us-east-2.amazonaws.com/webOrdering/TinyBaguette.png');
  height: 425px;
  background-size: cover;
}

.homeCarousel3mobile {
  background-image: url('https://extra-getit.s3.us-east-2.amazonaws.com/webOrdering/TinyBaguette.png');
  height: 300px;
  background-size: cover;
}

.ant-card-body {
  padding: 18px;
}
.ant-pagination-options {
  display: none;
}
.ant-avatar-square {
  border-radius: 6px;
}

.ottawaZoneCarousel {
  background-image: url('https://extra-getit.s3.amazonaws.com/webOrdering/getit_zones_apr2022_Ottawa-1024x593.png');
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  border-radius: 17px;
  height: 40vh;
}

.calgaryZoneCarousel {
  background-image: url('https://extra-getit.s3.amazonaws.com/webOrdering/calgary-1-1024x593.png');
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  border-radius: 17px;
  height: 40vh;
}

.ottawaZoneCarouselMobile {
  background-image: url('https://extra-getit.s3.amazonaws.com/webOrdering/getit_zones_apr2022_Ottawa-1024x593.png');
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: cover;
  border-radius: 17px;
  height: 50vh;
}

.calgaryZoneCarouselMobile {
  background-image: url('https://extra-getit.s3.amazonaws.com/webOrdering/calgary-1-1024x593.png');
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 170%;
  border-radius: 17px;
  height: 50vh;
}

.ant-carousel .slick-dots li button {
  background: #ef3340;
  opacity: 1;
}

.deliveryTimes {
  background-color: #ef3340;
  background-image: url('https://extra-getit.s3.amazonaws.com/webOrdering/arrows.png');
  background-repeat: repeat-x;
  background-position: 70%;
}

.deliveryTimesMobile {
  padding: 0 20px;
  background-color: #ef3340;
  background-image: url('https://extra-getit.s3.amazonaws.com/webOrdering/arrows.png');
  background-repeat: repeat-x;
  background-position: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.communitySupport {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.communitySupportMobile {
  padding: 0 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.advertisement {
  background-color: #ef3340;
  background-image: url('https://extra-getit.s3.amazonaws.com/webOrdering/header.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.advertisementMobile {
  padding: 0 30px;
  background-image: url('https://extra-getit.s3.amazonaws.com/webOrdering/header.jpeg');
  background-repeat: repeat-x;
  background-position: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.storeLinks {
  border: 2px solid #ddd;
  transition: border 0.5s;
  margin-right: 60px;
  margin-left: 0px;
  border-radius: 17px;
  border-collapse: separate;
  border-spacing: 15px;
  width: 330px;
  height: auto;
}

.storeLinksMobile {
  border: 2px solid #ddd;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  border-radius: 17px;
  border-collapse: separate;
  border-spacing: 15px;
  width: 300px;
  height: auto;
}

.storeLinks:hover {
  cursor: pointer;
  border: 2px solid #ef3340;
}

.storeRow {
  margin-top: 42px;
}

.popularStadiumStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stadiumImageStyle {
  border-top-right-radius: 17px;
  border-top-left-radius: 17px;
}

.stadiumTextField {
  height: 0;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
  border-bottom: 60px solid #ef3340;
  display: flex;
  justify-content: center;
  align-items: center;
}

.promosStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.promosMobile {
  padding: 0 17px;
}

.promosDescription {
  background-color: white;
  border-radius: 17px;
}
.ant-menu {
  background-color: transparent;
}

.ant-menu-submenu-popup > .ant-menu {
  background-color: rgba(0, 0, 0, 0.3);
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: transparent;
}

.applyHereButton {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 19px;
  line-height: 2px;
  font-weight: 600;
  border-radius: 8px;
  padding: 30px 44px;
  border: none;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s,
    -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
  background: linear-gradient(
    to right,
    rgb(230, 30, 77) 0%,
    rgb(227, 28, 95) 50%,
    rgb(215, 4, 102) 100%
  );
  color: #fff;
}

.applyHereLink {
  font-weight: bold;
  font-size: 22px;
  text-decoration: underline !important;
}
.ant-modal-title {
  font-weight: bold;
}

.ant-spin-text {
  font-weight: bold;
  color: black;
}

.ant-spin-dot-item {
  background-color: black !important;
}

.ant-form-item-label,
.ant-col-8  {
  max-width: 100%;
}

.ant-modal-content {
  border-radius: 6px;
}

.ant-modal-header {
  border-radius: 6px;
}

.ant-collapse-header-text {
  font-weight: bold;
}