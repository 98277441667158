.container {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.logoContainer{
  padding-top: 40px;
  padding-bottom: 30px;
}
.grayText{
  color: gray;
}
.content{
  padding: 20px;
}
.secureText{
  padding-left: 5px;
  color: gray;
}
.secureSmallText{
  padding-left: 5px;
  font-size: 10px;
  color: gray;
}
.secureBox{
  padding-bottom: 30px;
}
.totalText{
  color: gray;
}
.paymentAmountFormItem{
  margin-bottom: 20px;
  border-bottom: 1px solid #000;
  border-color: lightgray;
}
.formItems{
  margin-bottom: 20px;
}
.totalDivider{
  margin-top: 10px;
  margin-bottom: 10px;
}
.ant-typography {
  height: 5px;
}
.redText{
  color: red;
  font-size: 12px;
}
.submitButton {
  background-color: black !important;
  font-size: 18px;
  font-weight: bold;
  color: #fff !important;
  width: 100%;
  margin-top: 40px;
  height: 48px !important;
}
.text {
  padding: 10px;
  margin-top: 17px;
  color: gray;
}
.spaceCol{
  display: flex;
  justify-content: center;
}

@media screen and (max-device-width: 667px) {
  .logoContainer{
    padding-top: 20px;
    padding-bottom: 20px;  
  }
}