.orderNo {
  font-size: 28px;
}
.title {
  flex-direction: column;
  align-items: center;
}
.orderName {
  font-size: 28px;
  font-weight: bold;
}
.line {
  width: 13%;
  height: 3px;
  background-color: lightgrey;
  margin: 5px;
}
.deliveryOption {
  color: grey;
  font-size: 16px;
}
.ant-steps-item-container {
  height: 45px;
  margin: 7px;
}
.ant-steps-item-title {
  font-size: 14px;
  padding-top: 2px;
}
.statusIcon {
  border-radius: 100%;
  height: 40px;
  width: 40px;
  padding: 7px 6px 2px 6px;
}
.stutusContainer {
  width: 100%;
  justify-content: space-around;
}
.logoContainer {
  justify-content: space-between;
}
.dateContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
}
.dateText {
  font-size: 20px;
  font-weight: 500;
}
.timeText {
  font-size: 16px;
  color: gray;
  margin-top: -5px;
}
.orderText {
  font-size: 24px;
  font-weight: 500;
}
.stadiumAddressText {
  font-size: 16px;
  padding-bottom: 5px;
  margin-top: -5px;
  text-align: center;
}
.detailTitle {
  font-size: 16px;
  font-weight: 500;
}
.detailData {
  color: gray;
}
.detailsRow {
  flex-direction: column;
  padding-top: 10px;
}
.detailsCol {
  padding-bottom: 50px;
}
.inhouseContainer {
  padding-bottom: 40px;
}
.foodItemName {
  font-size: 16px;
  font-weight: bold;
}
.foodItemPrice {
  font-size: 16px;
  font-weight: 500;
}
.orderItemRow {
  padding-bottom: 25px;
}
.modContainer {
  display: flex;
  flex-direction: column;
}
.groupName {
  font-weight: bold;
  margin-top: 5px;
  color: gray;
  font-size: 16px;
}
.optionSelected {
  margin-left: 10px;
  color: black;
}
.eventName {
  font-size: 18px;
  font-weight: bold;
}
.admName {
  font-weight: bold;
}
.subtotalCol {
  display: flex;
  flex-direction: column;
}
.totalGray {
  color: gray;
  font-size: 16px;
  
}
.totalBlack {
  font-size: 16px;
  font-weight: 500;
}
.subtotalRow {
  justify-content: space-between;
  margin-bottom: 4px;
}
.prodItemContainer {
  display: flex;
  flex-direction: column;
}
.deliveryManCol {
  padding: 20px 0 20px 0;
}
.deliveryManRow {
  justify-content: space-between;
}
.deliveryManTitle {
  font-size: 18px;
  font-weight: 500;
}
.deliveryManPhoneCol {
  display: flex;
  align-items: center;
}
.deliveryManPhone {
  padding-left: 10px;
}
.imgProfile {
  width: 40px;
  height: 40px;
}
.deliveryManName {
  font-size: 17px;
  padding-left: 10px;
}
.deliveryManIcon {
  color: #ed1620;
  width: 30px;
}
.rejectText {
  font-size: 17px;
}
.rateOrderButton {
  width: 350px;
  height: 40px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 6px;
}
.rateOrderButtonRow {
  padding-top: 40px;
  justify-content: center;
}

@media screen and (max-device-width: 667px) {
  .ant-steps-item-tail {
    margin-left: 11px;
    margin-top: 11px;
  }
  .orderName,
  .orderNo {
    font-size: 18px;
  }
  .line {
    width: 25%;
  }
  .dateText,
  .deliveryManTitle {
    font-size: 16px;
  }
  .timeText,
  .detailTitle,
  .foodItemName,
  .foodItemPrice,
  .groupName,
  .eventName,
  .totalGray,
  .totalBlack,
  .deliveryManName,
  .rejectText,
  .stadiumAddressText {
    font-size: 14px;
  }
  .orderText {
    font-size: 18px;
  }
  .stadiumAddressText,
  .detailData,
  .eventTime,
  .admName,
  .admPrice {
    font-size: 12px;
  }
  .imgProfile {
    width: 30px;
    height: 30px;
  }
  .deliveryManPhoneCol {
    padding-top: 10px;
  }
}
