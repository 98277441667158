.container {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.logoContainer{
  padding-top: 40px;
  padding-bottom: 30px;
}
.content{
  padding: 20px;
}
.greenText{
  color:#4BAE4F;
  text-align: center;
  font-size: 16px;
  padding-top: 25px;
}
.desc{
  color: gray;
  text-align: center;
  padding-bottom: 60px;
}
.checkIcon{
  color:#4BAE4F;
  font-size: 65px;
}
.confirmationText{
  font-weight: 600;
}
.total{
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
}
.confirmationNumber{
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 90px;
}
.anotherPaymentButton{
  /* background-color: #000;
  color:white; */
  font-weight: 600;
  width: 100%;
}
.backLink{
  color: gray;
  padding-top: 5px;
  text-decoration: underline;
  cursor: pointer;
  padding-bottom: 100px;
}
.imageLogo{
  max-width: 180px;
  min-height: 50px;
  object-fit: contain;
  width: 180px;
}

@media screen and (max-device-width: 667px) {
  .logoContainer{
    padding-top: 20px;
    padding-bottom: 20px;  
  }
  .confirmationNumber{
    padding-bottom: 70px;
  }
  .desc{
    padding-bottom: 50px;
  }
  .imageLogo{
    max-height: 110px;
    min-height: 75px;
    object-fit: contain;
    width: 50%;
  }
}