.primary:focus {
  color: black;
  border-color: gray;
}

.primary:hover {
  color: black !important;
  border-color: gray !important;
}

.remove {
  color: red !important;
  margin-left: 20px;
}

.remove:hover {
  color: red !important;
  border-color: gray !important;
}