.itemCard {
  flex: "0 0 auto";
  width: 170px;
  background-color: #EBEBEB;
  border-radius: 6px;
}
.ItemImage {
  width: 170px !important;
  height: 150px !important;
  background-repeat: no-repeat;
  object-fit: cover;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.itemName {
  padding-left: 10px;
  margin-top: 10px;
  font-weight: bold;
}
.itemPrice {
  color: gray;
  padding-left: 10px;
}
.itemButton {
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: bold;
  color: white;
}