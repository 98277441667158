.main {
  margin-top: 64px;
  height: 300px;
  background-size: cover;
  background-position-y: center;
}
.container {
  display: flex;
  align-items: flex-end;
  height: 100%;
}
.textName {
  font-size: 28px;
  font-weight: bold;
  color: white;
}
.textAddress {
  font-size: 18px;
  color: white;
}
.column {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  width: 100%;
}

@media screen and (max-device-width: 667px) {
  .textName {
    font-size: 20px;
  }
  .textAddress {
    font-size: 14px;
  }
  .main {
    height: 200px;
  }
}
