.emptyCartContainer {
  text-align: center;
  margin-top: 150px;
}
.emptyCartText {
  font-size: 25px;
  color: black;
}
.emptyCartLink {
  font-weight: 600;
  color: #e72c3f;
  text-decoration-line: underline;
  cursor: pointer;
}
.cartsContainer {
  margin-top: 30px;
  padding-bottom: 30px;
}
.stadiumName {
  margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
}
.stadiumAddress {
  font-size: 14px;
}
.cartItemRow {
  padding: 20px;
  background-color: rgb(247, 245, 243);
  border-radius: 6px;
  margin-bottom: 20px;
}

.cartItemRow:hover {
  transform: scale(1.001);
  box-shadow: 1px 4px lightgray;
}
.foodItemsText {
  font-weight: bold;
  font-size: 16px;
  text-align: end;
}

.editItemsText {
  font-weight: bold;
  font-size: 16px;
  color: #ed1620;
  align-self: center;
  cursor: pointer;
}
.checkoutBtn {
  font-weight: bold;
  width: 220px;
  margin-left: 50px;
  border-radius: 6px;
  font-size: 16px;
  height: 40px;
  cursor: pointer;
}
.editCheckoutSection {
  width: 100%;
  align-items: center;
  padding-top: 20px;
}
.removeBtn {
  font-weight: 500;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}
.removeBtn:hover {
  color: gray;
}
.optionObjName {
  font-style: italic;
  font-size: 12px;
}
.optionObjExtraCost {
  font-weight: '600';
  font-size: 12px;
}
.optionGroupName {
  font-weight: 600;
}
.cartItemCol {
  padding: 10px 0 0px 0;
  width: 100%;
}
.cartItemImg {
  width: 85px;
  height: 85px;
  border-radius: 10px;
}
.quantityRow {
  border-radius: 6px;
  padding: 7px 0px 1px 0px;
  border: 1px solid gray;
  width: 140px;
  justify-content: space-around;
}
.cartItemName {
  font-size: 16px;
  font-weight: 600;
}
.quantityToPurchase {
  padding-left: 15px;
  padding-right: 16px;
  margin-top: -3px;
  font-size: 16px;
  font-weight: 600;
}
.editItemTitle {
  font-size: 20px;
  font-weight: bold;
}
.editLine {
  width: 100%;
  height: 2px;
  background-color: lightgray;
  margin-bottom: 20px;
}
.editBtn {
  width: 250px;
  height: 40px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 6px;
  margin: 20px 0 20px 0;
}
.cartAvatar {
  height: 90%;
  width: 90%;
  object-fit: 'contain',
}
.imgCol {
  width: 85px;
  height: 85px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
}
.ant-popover-inner {
  padding: 10px;
  width: 300px;
}

@media screen and (max-device-width: 667px) {
  .stadiumName {
    margin-top: 10px;
    font-size: 16px;
  }
  .cartItemName {
    font-size: 16px;
  }
  .stadiumAddress {
    font-size: 12px;
  }
  .foodItemsText,
  .editItemsText {
    font-size: 14px;
  }
  .checkoutBtn {
    width: 150px;
    font-size: 14px;
    height: 35px;
  }
  .editItemsText {
    padding-left: 30px;
  }
  .removeBtn {
    font-size: 12px;
  }
  .quantityRow {
    margin-top: 20px;
    padding: 6px 0px 1px 0px;
    width: 120px;
  }
  .cartAvatar {
    height: 100%;
    width: 100%;
  }
  .editItemsText {
    padding: 10px 0 10px 0;
  }
  .eventDate {
    font-size: 14px;
  }
  .removeBtn {
    font-size: 14px;
  }
  .optionGroupName {
    font-size: 14px;
  }
}

@media screen and (min-width: 1600px) {
  .cartAvatar {
    height: 160px;
    width: 160px;
  }
}
