.rowContainer {
  width: 100%;
}

.rowHeader {
  background-size: cover;
  background-position-y: center;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
}

.headerSolutionLogo {
  object-fit: contain;
  object-position: left;
  cursor: pointer;
}

.buttonContainer {
  margin-top: 16px;
  justify-content: space-between;
}

.cartLinkRow {
  align-items: center;
  cursor: pointer;
}

.drawerButton {
  color: white;
}

.amountBox {
  border: 1px solid lightgrey;
  width: 100px;
  border-radius: 0;
  height: 47px;
  color: gray;
}

.otherAmountBox {
  width: 100%;
}

.amountInput .ant-input {
  height: 47px;
  border-radius: 0;
}

.grayText {
  color: grey;
}

.amountInput .ant-input-group-addon {
  border-radius: 0 !important;
}

.textTitle {
  font-weight: 600;
  font-size: 16px;
}

.rowTitle {
  margin-top: 30px;
  margin-bottom: 15px;
  align-items: center;
}

.lockIcon {
  font-size: 20px;
  padding-left: 15px;
  padding-right: 3px;
}

.payButtons {
  width: 100%;
  margin-bottom: 15px;
}

.donateProblemsText {
  color: gray;
  padding-top: 20px;
}

.errorText {
  color: red;
  font-size: 12px;
}

.infoInputRow {
  padding-top: 15px;
}

.announcementContainer {
  border: 1px solid lightgrey;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 16px;
}

.announcementTitle {
  font-weight: bold;
  font-size: 16px;
}

.announcementTextContainer {
  padding-top: 200x;
}

@media screen and (max-device-width: 667px) {
  .donateButton {
    margin-right: 10px;
  }
}
