.mainCol{
  padding-top: 20px;
}
.locations {
  padding-top: 10px;
}
.cardLink {
  width: 100%;
  padding-top: 20px ;

}
.cardLink:hover {
  background-color: #FBFBFB;
  border: 1px solid #FBFBFB;
}
.divider{
  margin-bottom: 0 ;
  margin-top: 19px
}
.footerButton{
  height: 40px;
  width: 200px;
  font-weight: 600;
  margin-top: 25px;
}