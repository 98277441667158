.modal {
  padding: '30px';
}
.categoryName {
  font-weight: 600;
  font-size: 18px;
}
.name {
  font-weight: bold;
  font-size: 18px;
}
.price {
  font-weight: bold;
  font-size: 16px;
}
.textDesc {
  color: gray;
}
.titleColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}
.descDiv {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}
.titleRow {
  margin-top: 20px;
  margin-bottom: 25px;
}
.img {
  height: 200px;
  width: 200px;
  border-radius: 6px;
  margin-right: 20px;
}
.rowCenter {
  justify-content: 'center';
}

.quantityCol {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 25px;
  width: 100%;
}
.quantityButton {
  width: 48px;
  height: 48px;
  background-color: white;
  align-self: center;
  justify-content: center;
  align-items: center;
  /* border-color: #ed1620; */
  border-width: 1;
  border-radius: 100%;
  padding: 0;
}
.quantityButtonMinus {
  width: 48px;
  height: 48px;
  background-color: white;
  align-self: center;
  justify-content: center;
  align-items: center;
  border-width: 1;
  border-radius: 100%;
  padding: 0;
}
.quantityIcon {
  color: black;
  font-size: 24px;
  margin-top: 3px;
}
.quantityText {
  font-size: 18px;
}
.addButton {
  width: 70%;
  /* background-color: #ed1620; */
  border-radius: 6px;
  /* color: white; */
  font-weight: bold;
  font-size: 18px;
  margin: 10px;
  height: 50px;
}
.optionLine2 {
  font-size: 12px;
  color: gray;
}
.optionGroupName {
  font-weight: bold;
  font-size: 16px;
}
.optionGroupRow {
  /* padding-top: 25px; */
  flex-direction: column;
}
.requiredRow {
  padding-bottom: 10px;
  flex-direction: column;
}
.smallText {
  font-size: 14px;
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}
.optionColumn {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  align-items: center;
  padding-right: 10px;
}
.optionRow {
  cursor: pointer;
  margin: 5px 0 5px 0;
}
.optionRow:hover {
  transform: scale(1.001);
}

.divider {
  margin: 0;
}

.optionGroupDivider {
  height: 3px;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: #F3F3F3;
}

@media screen and (max-device-width: 667px) {
  .smallText {
    font-size: 12px;
  }
  .addButton {
    width: 100%;
    font-size: 16px;
    margin: 10px 0 10px 0;
    height: 40px;
  }
  .optionGroupName {
    font-size: 14px;
  }
  .modal {
    padding: '5px';
  }
  .titleColumn {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 5px;
  }
  .img {
    height: 150px;
    width: 150px;
  }
  .titleRow {
    flex-direction: column;
    /* align-items: center; */
  }
  .optionGroupDivider {
    margin-top: 0;
  }
}
